<template>
  <div class="draw-block-wraper">
    <el-drawer
      :visible.sync="drawerShow"
      :destroy-on-close="true"
      :wrapper-closable="false"
      :show-close="false"
      :with-header="false"
      :direction="'rtl'"
      size="70%"
      custom-class="invest-drawer"
    >
      <div
        v-if="drawerShow"
        class="demo-drawer__content"
      >
        <module-header :title="formData.id ? '编辑产品' : '添加产品'" />
        <div class="custom-draw-conent">
          <el-form
            ref="investValidateForm"
            :model="formData"
            label-width="100px"
            :label-position="'right'"
          >
            <div class="content-top">
              <div class="flex-row-start-start">
                <div>
                  <formItem
                    v-model="formData.productName"
                    required=":请输入产品名称"
                    label="产品名称:"
                    type="text"
                    direct="top"
                    prop="productName"
                  />
                  <formItem
                    v-model="formData.productPeriod"
                    label="产品期限:"
                    type="text"
                    direct="top"
                  />
                  <formItem
                    v-model="formData.productInfoLink"
                    label="产品说明链接:"
                    type="text"
                    direct="top"
                  />
                  <formItem
                    v-model="formData.enableAutomaticInvest"
                    label="可否定投:"
                    type="select"
                    required=":请选择可否定投"
                    prop="enableAutomaticInvest"
                    direct="top"
                    :options="[
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                  />
                  <formItem
                    v-model="formData.rootTypeId"
                    label="产品属性:"
                    type="select"
                    required=":请选择产品属性"
                    prop="rootTypeId"
                    direct="top"
                    :options="typeOptions"
                    :default-key="{
                      label: 'typeName',
                      value: 'id'
                    }"
                    double
                    @change="changeType"
                  />
                </div>
                <div>
                  <formItem
                    v-model="formData.currencyId"
                    label="币种:"
                    type="select"
                    required=":请选择币种"
                    prop="currencyId"
                    direct="top"
                    :options="currencyOptions"
                    :default-key="{
                      label: 'currencyName',
                      value: 'id'
                    }"
                  />
                  <formItem
                    v-model="formData.productExpectedReturn"
                    required="interTwo"
                    prop="productExpectedReturn"
                    label="产品历史收益率:"
                    type="text"
                    direct="top"
                    suffix="%"
                  />
                  <formItem
                    v-model="formData.productSd"
                    required="interTwo"
                    prop="productSd"
                    label="产品标准差:"
                    type="text"
                    direct="top"
                    suffix="%"
                  />
                  <formItem
                    v-model="formData.productDrawdown"
                    required="interTwo"
                    prop="productDrawdown"
                    label="产品历史最大回撤:"
                    type="text"
                    direct="top"
                    suffix="%"
                  />
                  <formItem
                    v-model="formData.productTypeId"
                    label="产品分类:"
                    type="select"
                    required=":请选择产品分类"
                    prop="productTypeId"
                    direct="top"
                    :options="typeOptionsTwo"
                    :default-key="{
                      label: 'typeName',
                      value: 'id'
                    }"
                  />
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="demo-drawer__footer flex-row-center">
          <el-button
            class="btn"
            @click="close"
          >
            返回
          </el-button>
          <el-button
            class="btn"
            type="primary"
            @click="confirm"
          >
            确定
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { CommonMudule } from '@/store/modules/common'
import moduleHeader from '@/components/moduleHeader/index.vue'
import formItem from '@/components/formItem/index.vue'
import {
  fetchInvestCurrencyList,
  fetchInvestProductTypeList,
  fetchSaveInvestProduct,
  fetchInvestProductDetail
} from '@/api/investProduct'
import { cloneDeep } from 'lodash'

@Component({
  name: 'pensionPlan',
  components: {
    moduleHeader,
    formItem
  }
})

export default class extends Vue {
  drawerShow = false
  formData = {
    id: '',
    productName: '',
    productPeriod: '',
    productInfoLink: '',
    enableAutomaticInvest: '',
    currencyId: '',
    productExpectedReturn: '',
    productSd: '',
    productDrawdown: '',
    rootTypeId: '',
    productTypeId: ''
  }
  typeOptions = []
  typeOptionsTwo = []
  currencyOptions = []
  show(row) {
    this.formData = {
      id: '',
      productName: '',
      productPeriod: '',
      productInfoLink: '',
      enableAutomaticInvest: '',
      currencyId: '',
      productExpectedReturn: '',
      productSd: '',
      productDrawdown: '',
      rootTypeId: '',
      productTypeId: ''
    }
    this.typeOptions = []
    this.typeOptionsTwo = []
    this.currencyOptions = []
    if (row) {
      this.formData.id = row.id
      this.initDetail()
    }
    this.fetchTypeList()
    this.drawerShow = true
  }
  close() {
    this.drawerShow = false
  }
  async initDetail() {
    const res = await fetchInvestProductDetail({
      productId: this.formData.id
    })
    if (res.success) {
      this.formData.productName = res.data.productName
      this.formData.productPeriod = res.data.productPeriod
      this.formData.productInfoLink = res.data.productInfoLink
      this.formData.enableAutomaticInvest = res.data.enableAutomaticInvest ? '1' : '2'
      this.formData.currencyId = res.data.currencyId
      this.formData.productExpectedReturn = res.data.productExpectedReturn
      this.formData.productSd = res.data.productSd
      this.formData.productDrawdown = res.data.productDrawdown
      this.formData.rootTypeId = res.data.rootTypeId + ''
      this.formData.productTypeId = res.data.productTypeId + ''
      this.formData.backTestSet = res.data.backTestSet
      this.formData.backTestInfoList = res.data.backTestInfoList
      this.formData.backTestIndicatorId = res.data.backTestIndicatorId
      this.formData.onSale = res.data.onSale
      if (this.formData.rootTypeId) {
        this.typeOptions.forEach((item) => {
          if (item.id === this.formData.rootTypeId) {
            this.typeOptionsTwo = cloneDeep(item.subProductTypes)
          }
        })
      }
    }
  }
  changeType() {
    this.typeOptionsTwo = []
    this.formData.productTypeId = ''
    this.typeOptions.forEach((item) => {
      if (item.id === this.formData.rootTypeId) {
        this.typeOptionsTwo = cloneDeep(item.subProductTypes)
      }
    })
  }
  confirm() {
    const value = this.$validator(this.$refs.investValidateForm)
    if (value) return
    const param = cloneDeep(this.formData)
    this.currencyOptions.forEach((item) => {
      if (item.id === param.currencyId) {
        param.currencyName = item.currencyName
        param.productCurrency = item.currencyType
      }
    })
    param.enableAutomaticInvest === '1' ? param.enableAutomaticInvest = true : param.enableAutomaticInvest = false
    delete param.rootTypeId
    param.id = param.id || undefined
    fetchSaveInvestProduct(param).then((res) => {
      if (res.success) {
        this.$message.success(this.formData.id ? '更新成功!' : '新增成功!')
        this.$emit('success')
        this.close()
      }
    })
  }
  async fetchTypeList() {
    const resType = await fetchInvestProductTypeList()
    if (resType.success) {
      this.typeOptions = resType.data
      if (this.formData.rootTypeId) {
        this.typeOptions.forEach((item) => {
          if (item.id === this.formData.rootTypeId) {
            this.typeOptionsTwo = cloneDeep(item.subProductTypes)
          }
        })
      }
    }
    const resCurrency = await fetchInvestCurrencyList()
    if (resCurrency.success) {
      this.currencyOptions = resCurrency.data
    }
  }
}
</script>

<style lang="scss" scoped>
.content-top{
  padding-top: 20px;
}
.content-label{
  padding-left: 80px;
  color: $fontGray;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
