<template>
  <el-dialog
    title="回测数据选择"
    :visible.sync="isShow"
    :close-on-click-modal="false"
    :show-close="false"
    width="800px"
    center
  >
    <div class="back-test-main">
      <div class="back-test-box" style="margin-right: 25px;">
        <module-header title="数据预览" />
        <div style="padding-top: 12px;">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            height="400"
          >
            <el-table-column
              prop="date"
              label="日期"
              align="center"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              prop="value"
              label="值"
              align="center"
              :show-overflow-tooltip="true"
            />
          </el-table>
          <!-- <pagination
            :total="totalSize"
            :limit="listQuery.size"
            layout="prev, pager, next"
            :page.sync="listQuery.page"
            :page-size="listQuery.size"
            :current-page="listQuery.page"
            :pager-count="5"
            @pagination="init"
          /> -->
        </div>
      </div>
      <div class="back-test-box">
        <module-header title="上传数据" />
        <div style="padding-top: 12px;">
          <div style="margin-bottom: 30px;">
            <el-button
              :type="dataType === 'new' ? 'primary' : ''"
              @click="setDataType('new')"
            >
              使用产品回测数据
            </el-button>
            <el-button
              :type="dataType === 'old' ? 'primary' : ''"
              @click="setDataType('old')"
            >
              使用指数指标对标
            </el-button>
          </div>
          <div
            v-if="dataType === 'new'"
          >
            <div class="back-test-title">
              上传文件
              <span>
                *请使用csv文件上传
              </span>
            </div>
            <div class="dialog-insurance-input">
              {{ fileName }}
              <span class="dialog-insurance-btn">
                选择文件
              </span>
              <input
                ref="file"
                name="file"
                class="dialog-insurance-file"
                type="file"
                accept=".csv"
                @change="changeFile"
              >
            </div>
          </div>
          <div
            v-else
          >
            <div class="back-test-title">
              选择相关指标
            </div>
            <el-select v-model="selectValue" placeholder="请选择">
              <el-option
                v-for="item in selectOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        class="btn"
        @click="close"
      >返回</el-button>
      <el-button
        class="btn"
        type="primary"
        @click="confirm"
      >确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { cloneDeep } from 'lodash'
import {
  fetchInvestProductDetail,
  fetchBackListAll,
  fetchSaveBackList
} from '@/api/investProduct'

@Component({
  name: 'pensionPlan',
  components: {
    moduleHeader,
    Pagination
  }
})

export default class extends Vue {
  isShow = false
  // totalSize = 0
  // listQuery = {
  //   page: 1,
  //   size: 20,
  //   productName: ''
  // }
  tableData = []
  dataType = 'new'
  fileName = ''
  // init() {}
  selectOptions = []
  selectValue = ''
  setDataType(type) {
    this.dataType = type
  }
  confirm() {
    const formData = new FormData()
    formData.append('productId', this.row.id)
    if (this.dataType === 'new') {
      const oFile = this.$refs.file
      if (oFile.files && oFile.files.length) {
        formData.append('file', oFile.files[0])
      } else {
        return this.$message.error('请选择文件')
      }
    } else {
      formData.append('indicatorId', this.selectValue)
    }
    fetchSaveBackList(formData).then((res) => {
      if (res.success) {
        this.$message.success('操作成功!')
        this.$emit('success')
        this.close()
      }
    })
  }
  changeFile() {
    const oFile = this.$refs.file
    if (oFile.files && oFile.files.length) {
      this.fileName = oFile.files[0].name
    }
  }
  async fetchList() {
    const resDetail = await fetchInvestProductDetail({
      productId: this.row.id
    }).then((res) => {
      if (res.success) {
        this.tableData = res.data.backTestInfoList || []
      }
    })
    const res = await fetchBackListAll({})
    if (res.success) {
      const list = []
      if (res.data && res.data.length) {
        res.data.forEach((item) => {
          if (item.indicators && item.indicators.length) {
            item.indicators.forEach((indicator) => {
              list.push(indicator)
            })
          }
        })
      }
      this.selectOptions = list
    }
  }
  show(row) {
    this.row = cloneDeep(row)
    this.dataType = 'new'
    // this.tableData = this.row.backTestInfoList || []
    this.selectValue = ''
    this.fileName = ''
    if (this.row.backTestIndicatorId) {
      this.selectValue = this.row.backTestIndicatorId
    }
    this.fetchList()
    this.isShow = true
  }
  close() {
    this.isShow = false
  }
}
</script>

<style lang="scss" scoped>
.back-test-main{
  display: flex;
  .back-test-box{
    flex: 1;
  }
  .back-test-title{
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 10px;
    span {
      color: #cccccc;
      font-size: 12px;
    }
  }
  .dialog-insurance-input{
    position: relative;
    cursor: pointer;
    height: 38px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    line-height: 38px;
    font-size: 12px;
    padding: 0 72px 0 12px;
    overflow: hidden;
    white-space: nowrap;
    .dialog-insurance-btn{
      position: absolute;
      top: 0;
      right: 12px;
      height: 38px;
      line-height: 38px;
      font-size: 12px;
      color: #2179ff;
    }
    .dialog-insurance-file{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
</style>
