var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"draw-block-wraper"},[_c('el-drawer',{attrs:{"visible":_vm.drawerShow,"destroy-on-close":true,"wrapper-closable":false,"show-close":false,"with-header":false,"direction":'rtl',"size":"70%","custom-class":"invest-drawer"},on:{"update:visible":function($event){_vm.drawerShow=$event}}},[(_vm.drawerShow)?_c('div',{staticClass:"demo-drawer__content"},[_c('module-header',{attrs:{"title":_vm.formData.id ? '编辑产品' : '添加产品'}}),_c('div',{staticClass:"custom-draw-conent"},[_c('el-form',{ref:"investValidateForm",attrs:{"model":_vm.formData,"label-width":"100px","label-position":'right'}},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"flex-row-start-start"},[_c('div',[_c('formItem',{attrs:{"required":":请输入产品名称","label":"产品名称:","type":"text","direct":"top","prop":"productName"},model:{value:(_vm.formData.productName),callback:function ($$v) {_vm.$set(_vm.formData, "productName", $$v)},expression:"formData.productName"}}),_c('formItem',{attrs:{"label":"产品期限:","type":"text","direct":"top"},model:{value:(_vm.formData.productPeriod),callback:function ($$v) {_vm.$set(_vm.formData, "productPeriod", $$v)},expression:"formData.productPeriod"}}),_c('formItem',{attrs:{"label":"产品说明链接:","type":"text","direct":"top"},model:{value:(_vm.formData.productInfoLink),callback:function ($$v) {_vm.$set(_vm.formData, "productInfoLink", $$v)},expression:"formData.productInfoLink"}}),_c('formItem',{attrs:{"label":"可否定投:","type":"select","required":":请选择可否定投","prop":"enableAutomaticInvest","direct":"top","options":[
                    {
                      label: '是',
                      value: '1'
                    }, {
                      label: '否',
                      value: '2'
                    }
                  ]},model:{value:(_vm.formData.enableAutomaticInvest),callback:function ($$v) {_vm.$set(_vm.formData, "enableAutomaticInvest", $$v)},expression:"formData.enableAutomaticInvest"}}),_c('formItem',{attrs:{"label":"产品属性:","type":"select","required":":请选择产品属性","prop":"rootTypeId","direct":"top","options":_vm.typeOptions,"default-key":{
                    label: 'typeName',
                    value: 'id'
                  },"double":""},on:{"change":_vm.changeType},model:{value:(_vm.formData.rootTypeId),callback:function ($$v) {_vm.$set(_vm.formData, "rootTypeId", $$v)},expression:"formData.rootTypeId"}})],1),_c('div',[_c('formItem',{attrs:{"label":"币种:","type":"select","required":":请选择币种","prop":"currencyId","direct":"top","options":_vm.currencyOptions,"default-key":{
                    label: 'currencyName',
                    value: 'id'
                  }},model:{value:(_vm.formData.currencyId),callback:function ($$v) {_vm.$set(_vm.formData, "currencyId", $$v)},expression:"formData.currencyId"}}),_c('formItem',{attrs:{"required":"interTwo","prop":"productExpectedReturn","label":"产品历史收益率:","type":"text","direct":"top","suffix":"%"},model:{value:(_vm.formData.productExpectedReturn),callback:function ($$v) {_vm.$set(_vm.formData, "productExpectedReturn", $$v)},expression:"formData.productExpectedReturn"}}),_c('formItem',{attrs:{"required":"interTwo","prop":"productSd","label":"产品标准差:","type":"text","direct":"top","suffix":"%"},model:{value:(_vm.formData.productSd),callback:function ($$v) {_vm.$set(_vm.formData, "productSd", $$v)},expression:"formData.productSd"}}),_c('formItem',{attrs:{"required":"interTwo","prop":"productDrawdown","label":"产品历史最大回撤:","type":"text","direct":"top","suffix":"%"},model:{value:(_vm.formData.productDrawdown),callback:function ($$v) {_vm.$set(_vm.formData, "productDrawdown", $$v)},expression:"formData.productDrawdown"}}),_c('formItem',{attrs:{"label":"产品分类:","type":"select","required":":请选择产品分类","prop":"productTypeId","direct":"top","options":_vm.typeOptionsTwo,"default-key":{
                    label: 'typeName',
                    value: 'id'
                  }},model:{value:(_vm.formData.productTypeId),callback:function ($$v) {_vm.$set(_vm.formData, "productTypeId", $$v)},expression:"formData.productTypeId"}})],1)])])])],1),_c('div',{staticClass:"demo-drawer__footer flex-row-center"},[_c('el-button',{staticClass:"btn",on:{"click":_vm.close}},[_vm._v(" 返回 ")]),_c('el-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v(" 确定 ")])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }