<template>
  <div class="page-container not-alone-plan plan invest-product-manage">
    <div class="left-container">
      <div class="left-wrapper-block alone-plan">
        <module-header title="投资产品管理">
          <div slot="top-icon" class="top-icon">
            <el-input
              v-model="searchTxt"
              placeholder="请输入产品名称"
              clearable
              @keyup.enter.native="search"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
                style="cursor: pointer;"
                @click="search"
              />
            </el-input>
          </div>
          <div slot="rightCom" class="head-btn-wrap">
            <el-button type="primary" @click="addProduct">
              添加产品
            </el-button>
            <el-button type="primary" @click="addStarProduct">
              选择明星产品
            </el-button>
          </div>
        </module-header>
        <div class="table-container">
          <el-table
            :data="tableData"
            border
            class="info-table"
            style="width: 100%"
            :height="tableHeight"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              label="产品名称"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="editProduct(scope.row)"
                >
                  {{ scope.row.productName }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="rootTypeName"
              label="产品属性"
              align="center"
              width="80"
            />
            <el-table-column
              prop="productTypeName"
              label="产品分类"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <div
                  style="width: 100%;white-space: nowrap;text-overflow:ellipsis;overflow:hidden;height: 30px;line-height: 30px;"
                >
                  {{ scope.row.productTypeName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="currencyName"
              label="投资币种"
              align="center"
              width="80"
            />
            <el-table-column
              prop="productPeriod"
              label="产品期限"
              align="center"
            />
            <el-table-column
              prop="productInfoLink"
              label="产品运营资料链接"
              align="center"
              width="200"
              style="overflow: hidden;"
            >
              <template slot-scope="scope">
                <div
                  style="width: 100%;white-space: nowrap;text-overflow:ellipsis;overflow:hidden;height: 30px;line-height: 30px;"
                >
                  {{ scope.row.productInfoLink }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="productExpectedReturn"
              label="产品历史收益率（%）"
              align="center"
              width="160"
            />
            <el-table-column
              prop="productSd"
              label="产品标准差（%）"
              align="center"
              width="140"
            />
            <el-table-column
              prop="productDrawdown"
              label="历史最大回撤（%）"
              align="center"
              width="160"
            />
            <el-table-column
              label="产品回测数据选择"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="showBackTestDialog(scope.row)"
                >
                  {{ scope.row.backTestSet ? '调整' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="enableAutomaticInvest"
              label="可否定投"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.enableAutomaticInvest ? '是' : '否' }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="onSale"
              label="上架"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.onSaleSelect"
                  placeholder="请选择上架"
                  @change="onSaleSelect(scope.row)"
                >
                  <el-option
                    v-for="item in [
                      {
                        label: '是',
                        value: '1'
                      }, {
                        label: '否',
                        value: '2'
                      }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <!-- <span>
                  {{ scope.row.onSale ? '是' : '否' }}
                </span> -->
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="60"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="deleteItem(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="totalSize"
            :limit="listQuery.size"
            layout="prev, pager, next"
            :page.sync="listQuery.page"
            :page-size="listQuery.size"
            :current-page="listQuery.page"
            :pager-count="5"
            @pagination="init"
          />
        </div>
      </div>
    </div>
    <invest-drawer
      ref="investDrawer"
      @success="resetFetch"
    />
    <back-test
      ref="backTestDialog"
      @success="fetchAgain"
    />
    <star-product
      ref="starProduct"
    />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import SplitPane from 'vue-splitpane'
import moduleHeader from '@/components/moduleHeader/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import InvestDrawer from './template/investDrawer.vue'
import BackTest from './template/backTestDialog.vue'
import StarProduct from './template/starProduct'
import {
  fetchInvestProductPage,
  fetchSaveInvestProduct,
  fetchOnSale
} from '@/api/investProduct'
import { cloneDeep } from 'lodash'

@Component({
  name: 'pensionPlan',
  components: {
    SplitPane,
    moduleHeader,
    Pagination,
    InvestDrawer,
    BackTest,
    StarProduct
  }
})

export default class extends Vue {
  totalSize = 0
  listQuery = {
    page: 1,
    size: 20,
    productName: ''
  }
  tableData = []
  searchTxt = ''
  tableHeight = 100
  onSaleSelect(row) {
    let onsale = true
    if (row.onSaleSelect !== '1') {
      onsale = false
    }
    fetchOnSale({
      onsale: onsale,
      productId: row.id
    }).then((res) => {
      if (res.success) {
        this.$message.success('操作成功')
        row.onSaleSelect === '1' ? row.onSale = true : row.onSale = false
      } else {
        row.onSale ? row.onSaleSelect = '1' : row.onSaleSelect = '2'
      }
    })
  }
  showBackTestDialog(row) {
    this.$refs.backTestDialog.show(row)
  }
  deleteItem(row) {
    let msg = '是否删除空行?'
    if (row.name) {
      msg = `是否删除${row.productName}?`
    }
    this.$confirm(msg, '删除产品', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      row.deleted = true
      fetchSaveInvestProduct(row).then((res) => {
        if (res.success) {
          this.$message.success('删除成功!')
          this.resetFetch()
        }
      })
    }).catch(() => {})
  }
  editProduct(row) {
    this.$refs.investDrawer.show(row)
  }
  addProduct() {
    this.$refs.investDrawer.show()
  }
  addStarProduct() {
    // console.log(this)
    this.$refs.starProduct.show()
  }
  search() {
    this.listQuery.productName = this.searchTxt
    this.init()
  }
  resetFetch() {
    this.listQuery.page = 1
    this.listQuery.size = 20
    this.listQuery.productName = ''
    this.init()
  }
  fetchAgain() {
    this.init()
  }
  init() {
    const param = cloneDeep(this.listQuery)
    param.page -= 1
    fetchInvestProductPage(param).then((res) => {
      if (res.success) {
        res.data.content.forEach((item) => {
          item.onSaleSelect = item.onSale ? '1' : '2'
        })
        this.tableData = res.data.content
        this.totalSize = res.data.totalElements
      } else {
        this.$message({
          message: res.errorMsg,
          type: 'error'
        })
      }
    })
  }
  onResize() {
    let tableHeight = document.body.clientHeight - 270
    tableHeight = tableHeight > 100 ? tableHeight : 100
    this.$nextTick(() => {
      this.tableHeight = tableHeight
    })
  }
  mounted() {
    window.addEventListener('resize', this.onResize, false)
    this.onResize()
    this.init()
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
.invest-product-manage{
  .table-container{
    .el-table .el-table__body td{
      color: $fontGray;
      font-size: 14px;
    }
    .el-table th > .cell{
      font-weight: 500;
      font-size: 14px;
      color: #3a3a3a;
    }
    .el-select .el-input {
      width: 110px
    }
  }
}
</style>

<style lang="scss" scoped>
.head-btn-wrap{
  width: 100%;
  margin-top: 15px;
}
.left-container{
  background-color: $bgGray;
  .left-wrapper-block{
    background: $fff;
    .pension-form-wrapper{
      padding-top: 30px;
      margin-bottom: 30px;
    }
  }
  .top-icon{
    margin-left: 30px;
    width: 600px;
  }
  .table-container{
    margin: 30px 47px 0 47px;
  }
}
</style>
