



























































































import { Component, Vue } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { fetchProductList } from '@/api/portfolio'
import { starProductList, saveStarProduct } from '@/api/investProduct'
@Component({
  components: {
    moduleHeader,
  },
})
export default class StarProduct extends Vue {
  visible: boolean = false;

  itemArr:any = [
    {
      type: 'FirstChoice',
      products: {
        productName: '首选产品',
        productArr: [],
        value: '',
      },
      startAmount: 1,
      stepAmount: 1,
    },
    {
      type: 'AlternativeChoice',
      products: {
        productName: '备选产品',
        productArr: [],
        value: '',
      },
      startAmount: 1,
      stepAmount: 1,
    },
    {
      type: 'AutomaticProduct',
      products: {
        productName: '定投产品',
        productArr: [],
        value: '',
      },
      startAmount: 1,
      stepAmount: 1,
    },
  ];
  formArr = [
    { itemArr: cloneDeep(this.itemArr), name: '现金类' },
    { itemArr: cloneDeep(this.itemArr), name: '固收类' },
    { itemArr: cloneDeep(this.itemArr), name: '权益类' },
    { itemArr: cloneDeep(this.itemArr), name: '另类' },
  ];
  formArrCopy = [
    { itemArr: cloneDeep(this.itemArr), name: '现金类' },
    { itemArr: cloneDeep(this.itemArr), name: '固收类' },
    { itemArr: cloneDeep(this.itemArr), name: '权益类' },
    { itemArr: cloneDeep(this.itemArr), name: '另类' },
  ];
  show() {
    this.getData()
    this.visible = true
  }

  async getStarProductList() {
    const { data } = await starProductList()
    for (const key in data) {
      this.formArr.forEach((item:any) => {
        // if (data[item.name]) {
        if (item.name === key) {
          data[key].forEach((_item:any) => {
            item.itemArr.forEach((w:any) => {
              // eslint-disable-next-line no-cond-assign
              if (w.type === _item.starProductType) {
                w.products.value = _item.productId
                w.startAmount = _item.startAmount
                w.stepAmount = _item.stepAmount
              }
            })
          })
        }
      })
    }
  }

  async getData() {
    const res = await fetchProductList({ onSale: true, size: 1000 })

    var obj: any = {}
    res.data.content.forEach((item: any) => {
      if (!obj[item.rootTypeName]) {
        obj[item.rootTypeName] = [item]
      } else {
        obj[item.rootTypeName].push(item)
      }
    })
    this.formArr.forEach((item: any) => {
      if (obj[item.name]) {
        item.itemArr.forEach((w:any) => {
          // 定投产品 enableAutomaticInvest true 定投
          if (w.type === 'AutomaticProduct') {
            w.products.productArr = obj[item.name].filter((item:any) => item.enableAutomaticInvest)
          } else {
            w.products.productArr = obj[item.name]
          }
        })
      }
    })
    this.getStarProductList()
  }
  close() {
    this.formArr = this.formArrCopy
    this.visible = false
  }
  async confirm() {
    const _arr:any = []
    for (let i = 0; i < this.formArr.length; i++) {
      const item = this.formArr[i]
      for (let j = 0; j < item.itemArr.length; j++) {
        const w = item.itemArr[j]
        if (w.stepAmount <= 0) {
          this.$message({
            type: 'warning',
            message: '产品递投金额不得小于0'
          })
          return
        }
        if (w.products.value) {
          const _product = w.products.productArr.find((v:any) => v.id === w.products.value)
          if (_product) {
            const obj = { ..._product,
              starProductType: w.type,
              rootTypeName: item.name,
              productId: _product.id,
              startAmount: +w.startAmount,
              stepAmount: +w.stepAmount }
            delete obj.id
            _arr.push(obj)
          }
        }
      }
    }

    await saveStarProduct(_arr)
    this.visible = false
    this.formArr = this.formArrCopy
  }
}
