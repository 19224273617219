import request from '@/utils/request'
import { investProductPageParam } from '@/types/investProduct'

/**
 *  获取产品列表
 */
export const fetchInvestProductPage = (params: investProductPageParam) =>
  request({
    url: '/blackcat/product/page',
    method: 'get',
    params
  })

/**
 *  获取所有币种
 */
export const fetchInvestCurrencyList = (params: any) =>
  request({
    url: '/blackcat/currency/list',
    method: 'get',
    params
  })

/**
 *  获取产品类别列表
 */
export const fetchInvestProductTypeList = (params: any) =>
  request({
    url: '/blackcat/product/type/list',
    method: 'get',
    params
  })

/**
 *  保存产品信息
 */
export const fetchSaveInvestProduct = (data: any) =>
  request({
    url: '/blackcat/product/save',
    method: 'post',
    data,
    headers: {
      contentType: 'application/json'
    }
  })

/**
 *  获取产品详情
 */
export const fetchInvestProductDetail = (params: any) =>
  request({
    url: '/blackcat/product/detail',
    method: 'get',
    params
  })

/**
 *  获取backlist列表
 */
export const fetchBackListAll = (params: any) =>
  request({
    url: '/blackcat/indicator/list',
    method: 'get',
    params
  })

/**
 *  保存backlist info
 */
export const fetchSaveBackList = (data: any) =>
  request({
    url: '/blackcat/product/back/test/save',
    method: 'post',
    data,
    headers: {
      contentType: 'application/json'
    }
  })

/**
 *  上架产品
 */
export const fetchOnSale = (data: any) =>
  request({
    url: '/blackcat/product/onsale' + `?onsale=${data.onsale}&productId=${data.productId}`,
    method: 'post',
    data,
    headers: {
      contentType: 'application/json'
    }
  })

/**
   * 明星产品列表
   */

export const starProductList = () =>
  request({
    url: '/blackcat/product/star/map',
    method: 'post',
    headers: {
      contentType: 'application/json'
    }
  })

/**
 *  保存明星产品
 */
export const saveStarProduct = (data: any) =>
  request({
    url: '/blackcat/product/star/batch/save',
    method: 'post',
    data,
    headers: {
      contentType: 'application/json'
    }
  })
